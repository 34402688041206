import { rgba } from 'polished'
import affinityTheme from '@allied-solutions/affinity-sd'

// include typeStyle, but remove color and text-align so we can set them on the fly
const _ = typeStyle => ({
  ...typeStyle,
  color: undefined,
  textAlign: undefined,
  fontWeight: affinityTheme.fontWeights.black,
})

const theme = {
  ...affinityTheme,
  buttons: {
    ...affinityTheme.buttons,
    primary: 'blue',
    fancy: 'fancy',
  },
  colors: {
    ...affinityTheme.colors,
    lightBlue120: rgba(affinityTheme.colors.lightBlue, 0.12),
  },
  fontSizes: {
    ...affinityTheme.fontSizes,
    inputs: {
      ...affinityTheme.fontSizes.inputs,
      default: affinityTheme.fontSizes.inputs.lg,
    },
  },

  sizes: {
    ...affinityTheme.sizes,
    inputs: {
      ...affinityTheme.sizes.inputs,
      default: affinityTheme.sizes.inputs.lg,
    },
  },
  typeStyles: {
    ...affinityTheme.typeStyles,
    h1: {
      ..._(affinityTheme.typeStyles.displayLarge),
      [affinityTheme.mediaQueries.md]: {
        // fontSize: affinityTheme.fontSizes[8],
        // lineHeight: affinityTheme.lineHeights[8],
      },
    },
    h2: {
      ..._(affinityTheme.typeStyles.displayMedium),
      [affinityTheme.mediaQueries.md]: _(
        affinityTheme.typeStyles.displayMedium
      ), //displayLarge
    },
    h3: {
      // ...affinityTheme.typeStyles.titleLarge, //displaySmall
      ..._(affinityTheme.typeStyles.displaySmall),
      // [affinityTheme.mediaQueries.md]: _(
      //   affinityTheme.typeStyles.displayMedium
      // ),
    },
    h4: {
      ..._(affinityTheme.typeStyles.headlineLarge),
      [affinityTheme.mediaQueries.md]: _(affinityTheme.typeStyles.displaySmall),
    },
    h5: {
      ..._(affinityTheme.typeStyles.headlineMedium),
      [affinityTheme.mediaQueries.md]: _(
        affinityTheme.typeStyles.headlineLarge
      ),
    },
    h6: _(affinityTheme.typeStyles.headlineSmall),
    primary: affinityTheme.typeStyles.bodyLarge,
  },
  breakpoints: ['640px', '768px', '1024px', '1280px', '1536px'],
}

const dark = {
  ...theme,
  colors: {
    ...theme.colors,
    background: theme.colors.darkBlue,
  },
  typeStyles: {
    ...theme.typeStyles,
    primary: theme.typeStyles.bodyMediumLeftWhite,
  },
}
// console.log('typestyle', affinityTheme.typeStyles)
export { dark, theme }
