import React, {
  createContext,
  useCallback,
  useEffect,
  useMemo,
  useReducer,
} from 'react'
import { throttle } from 'lodash'

const store = createContext()
const { Provider } = store

const getParameterByName = name => {
  if (typeof window === 'undefined') return
  let url = window.location.href
  name = name.replace(/[[\]]/g, '\\$&')
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

const initialState = {
  borrower: {
    ReferenceID: '',
    Originator: 'Borrower',
    SourceLinkedFrom: getParameterByName('s') || '',
    NoRef_Kind: '',
    NoRef_Other: false,
    MustAttachProofOfInsurance: 'false',
    IsAgent: false,
  },
  form: null,
}

const loadState = initialState => {
  try {
    const serializedState = sessionStorage.getItem('state')

    if (serializedState === null) {
      return initialState
    }

    const deserializedState = JSON.parse(serializedState)

    const source = getParameterByName('s')
    if (source) {
      deserializedState.borrower.SourceLinkedFrom = source
    }

    return deserializedState
  } catch (error) {
    return initialState
  }
}

const saveState = state => {
  try {
    const serializedState = JSON.stringify(state)
    sessionStorage.setItem('state', serializedState)
  } catch (error) {
    // Ignore write errors.
  }
}

const reducer = (state, action) => {
  const { type, payload } = action

  switch (type) {
    case 'addToState': {
      return {
        ...state,
        ...payload,
      }
    }
    case 'resetForm': {
      return {
        ...initialState,
      }
    }
    default:
      throw new Error('bad action type')
  }
}

const StateProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState, loadState)

  console.count('StateProvider render')
  // console.log(state)

  // only save state to sessionStorage at most once per second
  // (serialization is CPU $$$)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledSaveState = useCallback(
    throttle(state => saveState(state), 1000),
    []
  )

  // memoize aka cache state and dispatch separately for performance gains
  const contextValue = useMemo(() => ({ state, dispatch }), [state, dispatch])

  // watch for state changes and write it to sessionStorage
  useEffect(
    () => {
      if (
        state.borrower?.PolicyholderAddress1 &&
        !state.borrower?.FullAddress
      ) {
        dispatch({
          type: 'addToState',
          payload: {
            borrower: {
              ...state.borrower,

              // prettier-ignore
              FullAddress: `${state.borrower.PolicyholderAddress1 + (state.borrower.PolicyholderAddress2 ? ` ${state.borrower.PolicyholderAddress2},` : `,`)} ${state.borrower.PolicyholderCity}, ${state.borrower.PolicyholderState} ${state.borrower.PolicyholderZip}`,
            },
          },
        })
      }
      throttledSaveState(state)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [state]
  )

  // set originator to agent if IsAgent is true
  useEffect(() => {
    if (state.form?.IsAgent) {
      dispatch({
        type: 'addToState',
        payload: { form: { ...state.form, Originator: 'Agent' } },
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.form?.IsAgent])

  return <Provider value={contextValue}>{children}</Provider>
}

export { store, StateProvider }
