import React from 'react'
import { AffinityProvider, GlobalStyle } from '@allied-solutions/affinity'

import { theme } from './src/theme.js'
import { StateProvider } from './src/store'

export default ({ element }) => {
  return (
    <AffinityProvider theme={theme}>
      <GlobalStyle />
      <StateProvider>{element}</StateProvider>
    </AffinityProvider>
  )
}
